'use strict';

// 舊IE提醒
var userAgent = window.navigator.userAgent;
if (
	userAgent.indexOf('MSIE 7.0') > 0 ||
	userAgent.indexOf('MSIE 8.0') > 0 ||
	userAgent.indexOf('MSIE 9.0') > 0 ||
	userAgent.indexOf('MSIE 10.0') > 0 ||
	!!userAgent.match(/Trident.*rv\:11\./) // IE11
) {
	location.href = 'browser.html';
}

(function (window, document) {
	/* ----------------------------------- [START] Windows Setting */
	const html = document.documentElement;
	const body = document.body || document.querySelector('body');
	let ww = window.innerWidth;
	let wh = window.innerHeight;
	let ws = 0;
	function getScrollTop(target = window) {
		return (target.pageYOffset || html.scrollTop) - (html.clientTop || 0);
	}
	function getWinSet() {
		ww = window.innerWidth;
		wh = window.innerHeight;
		ws = getScrollTop();
	}
	on(window, 'load', getWinSet);
	on(window, 'resize', getWinSet);
	/* ----------------------------------- [END] Windows Setting */

	/* ----------------------------------- [START] 取得裝置判斷 */
	// 取得裝置判斷
	var isMobile = false;
	var isTablet = false;
	var isPhone = false;
	var deviceDetect = function () {
		// IsPhone
		isPhone = ww <= 640;

		// IsMobile
		// 防止測試時一直用開發者工具Resize出現Bug
		isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		if (isMobile) html.classList.add('is-mobile');
		else html.classList.remove('is-mobile');

		// IsTablet
		if (navigator.userAgent.match(/Android/i)) {
			if (!navigator.userAgent.match(/Mobile/i)) {
				isTablet = true;
			}
		} else if (navigator.userAgent.match(/BlackBerry|iPad|Opera Mini|IEMobile/i)) {
			isTablet = true;
		}
	};
	deviceDetect();
	on(window, 'resize', throttle(deviceDetect, 50, 100));
	/* ----------------------------------- [END] 取得裝置判斷 */

	/* ----------------------------------- [START] 判斷browser */
	var ua = navigator.userAgent;
	var browser = {
		isChrome: /chrome/i.test(ua),
		isFirefox: /firefox/i.test(ua),
		isSafari: /safari/i.test(ua),
		isIE: /msie/i.test(ua) || /trident/i.test(ua),
		isEdge: /edge/i.test(ua),
	};

	// 修正數值browser
	if (browser.isChrome) browser.isSafari = false;
	if (browser.isEdge) {
		browser.isChrome = false;
		browser.isSafari = false;
	}

	var browserIs;
	for (var key in browser) {
		if (browser[key]) {
			browserIs = key.split('is')[1];
			// 增加Class
			document.documentElement.classList.add(browserIs.toLowerCase());
			document.documentElement.browser = browserIs;
			break;
		}
	}
	browser.is = browserIs;

	// ----------------------------
	// 判斷 裝置
	// iOS
	var isiOS = ua.toLowerCase().match(/(iphone|ipod|ipad)/);
	isiOS && html.classList.add('ios');
	/* ----------------------------------- [END] 判斷browser */

	/* ----------------------------------- [START] window Resize end Event */
	// Ref: https://stackoverflow.com/a/20384135/11240898
	/* Create the event */
	var event = new CustomEvent('resizeEnd');

	/* Create trigger to resizeEnd event */
	window.addEventListener('resize', function () {
		if (this.resizeTimer) {
			clearTimeout(this.resizeTimer);
		}
		this.resizeTimer = setTimeout(function () {
			/* Dispatch/Trigger/Fire the event */
			window.dispatchEvent(event);
		}, 100);
	});
	/* ----------------------------------- [END] window Resize end Event */

	/* ----------------------------------- [START] Loader 移除 */
	var loaderRemove = function () {
		var loader = document.querySelector('#loader');
		window.loader = loader; // 加到 window 上

		pageUnLoading();
	};
	window.addEventListener('load', loaderRemove);

	/* 頁面可呼叫的 function -------- */
	/* 開啟 Loading */
	window.pageLoading = function () {
		document.body.appendChild(loader);
		setTimeout(function () {
			loader.classList.remove('is-loaded');
		}, 100);
	};

	/* 關閉 Loading */
	window.pageUnLoading = function () {
		loader.classList.add('is-loaded');
		setTimeout(function () {
			loader.remove();
		}, 2000);
	};
	/* ----------------------------------- [END] Loader 移除 */

	/* ----------------------------------- [START] Ease scroll */
	var buildEaseScroll = function () {
		if (window.EaseScroll === undefined) return false;
		const es = new EaseScroll({
			frameRate: 60,
			animationTime: 1000,
			stepSize: 100,
			pulseAlgorithm: 1,
			pulseScale: 6,
			pulseNormalize: 1,
			accelerationDelta: 20,
			accelerationMax: 1,
			keyboardSupport: true,
			arrowScroll: 30,
			touchpadSupport: true,
			fixedBackground: true,
			// disabledClass: 'modal-open',

			/* Browser Setting Control */
			browser: {
				Chrome: true,
				FireFox: true,
				Safari: true,
				IE: true,
				Edge: true,
			},
		});
	};
	on(window, 'load', buildEaseScroll);
	/* ----------------------------------- [END] Ease scroll */

	/* ----------------------------------- [START] Lazyload */
	/*
	 * 使用：https://github.com/tuupola/lazyload
	 * 尋找頁面上的 .lazyload 為執行 lazy load 物件
	 */
	var lazyloadTimer = 0;
	function buildLazyLoad() {
		if (lazyloadTimer < 5 && window.lazyload === undefined) {
			return setTimeout(function () {
				lazyloadTimer++;
				buildLazyLoad();
			}, 500);
		}
		lazyload();
	}
	on(window, 'load', buildLazyLoad);
	/* ----------------------------------- [END] Lazyload */

	/* ----------------------------------- [START] Click Blur Focus */
	// UAT 162 - 客戶要求 點擊後不要產生focus狀態
	$(document).on('click', 'a, button, [tabindex]', function() {
		var _this = this;
		if (_this.tagName.toUpperCase().match(/(A|BUTTON|DIV|SPAN)/gi)) {
			setTimeout( function() {
				_this.blur();
			}, 20);
		}
	});
	/* ----------------------------------- [END] Click Blur Focus */

	/* ----------------------------------- [START] Foundation */
	/* Init Foundation  */
	// Popup(Reveal) - Open
	function fdReveal() {
		var revealActiveEl = null; // 紀錄目前正開啟的項目
		var revealAlignMiddle = function() {
			// 調整內容為垂直置中
			var $self = this.tagName !== undefined ? $(this) : revealActiveEl;
			if ($self) {
				var top = (wh - $self.innerHeight()) / 2;
				if (top < 0) top = 0;
				$self.css('top', top);
			}
		}
		var revealAlignMiddleThrottle = throttle(revealAlignMiddle, 50, 200);

		// 開啟後置中 + 開啟Resize監測
		$(document).on('open.zf.reveal', '[data-reveal]', function() {
			revealActiveEl = $(this); // 紀錄項目
			revealAlignMiddle(); // 設定垂直置中
			$(window).on('resize', revealAlignMiddleThrottle); // 啟動Resize監測
			$(window).on('resizeEnd', revealAlignMiddle); // 啟動Resize監測
		});

		// 關閉後
		$(document).on('closed.zf.reveal', '[data-reveal]', function () {
			// 移除焦點
			setTimeout( function() {
				$(document.activeElement).blur();
			}, 20);

			// 關閉Resize監測
			if (revealActiveEl !== null) {
				$(window).off('resize', revealAlignMiddleThrottle); // 啟動Resize監測
				$(window).off('resizeEnd', revealAlignMiddle); // 啟動Resize監測
				revealActiveEl = null;
			}
		});
	}

	// Menu - Close
	function fdOffCanvas() {
		var hamburgerMenuClassIsActive = 'is-menu-open';
		var hamburgerMenu = document.querySelector('#hd-ham');
		$(document).on('close.zf.offCanvas', '[data-off-canvas]', function () {
			hamburgerMenu.classList.remove(hamburgerMenuClassIsActive);
			if (ww >= 1024) {
				header.classList.remove(hamburgerMenuClassIsActive);
				mainMenu.classList.remove(hamburgerMenuClassIsActive);
			}
		});
	}

	function fdDropdown() {
		let dropdownActive = null; // 紀錄目前開啟的 Dropdown
		let dropdownAnchor = null;
		// =========== Document Event

		const anchorClick = function() {
			dropdownClose();
		}

		// =========== Panel Event

		function dropdownClose() {
			dropdownActive && $(dropdownActive).foundation('close');
			dropdownAnchor && dropdownAnchor.off('click', anchorClick);

			dropdownActive = null;
			dropdownAnchor = null;
		}

		// =========== Event

		$(document).on('show.zf.dropdown', function(e) {
			dropdownActive = e.target;

			// 點擊子內容 <a>, <button> 關閉面板
			// .dropdown-panel 要增加屬性 data-close-on-inside-click="true"
			if ($(dropdownActive).attr('data-close-on-inside-click') === 'true') {
				dropdownAnchor = $(dropdownActive).find('a, button');

				setTimeout(() => {
					dropdownAnchor.on('click', anchorClick);
				}, 100);
			}
		});
		
		$(document).on('hide.zf.dropdown', function(e) {
			dropdownClose();
		});
	}

	function fdTabs() {
		$('[data-tabs]').on('change.zf.tabs', function() {
			scrollTriggerAni();
		});
	}

	on(window, 'load', function () {
		// Start
		$(document).foundation();

		fdReveal(); // Popup - Open
		fdOffCanvas(); // Menu - Close
		fdDropdown(); // Dropdown
		fdTabs(); // Tabs
	});
	/* ----------------------------------- [END] Foundation */

	/* ----------------------------------- [START] Hamburger Click */
	// PC(1024+)與Mobile是不同系統
	// Mobile: Foundation Off-canvas
	// PC: CSS class
	// ※Mobile 轉換為 PC 版時選單會關閉
	const mainMenu = document.querySelector('#hd-menu');
	const hamburgerMenuClassIsActive = 'is-menu-open';
	const headerClassScroll = 'is-collapse';
	function hamburgerMenuClickBuild() {
		var result = {};

		var hamburgerMenu = document.querySelector('#hd-ham');
		result.isOpen = false;

		// 避免SVG被Focus（Chrome 預設 SVG物件包含子物件都可以被focus）
		const svg = hamburgerMenu.querySelector('svg');
		const svgEls = svg.querySelectorAll('*');
		svg.setAttribute('tabindex', -1);
		svgEls.forEach((item) => {
			item.setAttribute('tabindex', -1);
		})

		// 找內容第一個連結 ( close 要 focus + blur )
		const mainContFirstCTA = document.querySelector('main a').parentElement;
		mainContFirstCTA.setAttribute('tabindex', -1);

		var mainMenuOpen = function () {
			// 開啟
			// -------- [START] Ham Icon
			hamburgerMenu.setAttribute('aria-expanded', true);
			hamburgerMenu.classList.add(hamburgerMenuClassIsActive);
			html.classList.add(hamburgerMenuClassIsActive);

			// Prevent Page Scrolling When a Modal is Open
			// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
			// When the modal is shown, we want a fixed body
			body.style.overflow = 'hidden';
			// -------- [END] Ham Icon

			if (ww >= 1024) {
				header.classList.add(hamburgerMenuClassIsActive);
				mainMenu.classList.add(hamburgerMenuClassIsActive);

				// 選取 Active 的第一個<a> Focus + Blur
				if (!isMobile) {
					const firstCTA = mainMenu.querySelector('.is-active').querySelector('a');
					firstCTA.setAttribute('tabindex', -1);
					firstCTA.focus();
					firstCTA.blur();
					firstCTA.removeAttribute('tabindex');
				}

				if (!result.isOpen && !isMobile) {
					on(document, 'keydown', docKeyboard);

					if (header.classList.contains(headerClassScroll)){
						setTimeout(() => {
							header.classList.add(headerClassScroll);
						}, 1);
					}

					window.docDisableScroll && docDisableScroll(true); // 徹底鎖滑動
				}
			}
			result.isOpen = true;
		};

		var mainMenuClose = function () {
			// 關閉
			// -------- [START] Ham Icon
			hamburgerMenu.setAttribute('aria-expanded', false);
			hamburgerMenu.classList.remove(hamburgerMenuClassIsActive);
			html.classList.remove(hamburgerMenuClassIsActive);

			// When the modal is hidden, we want to remain at the top of the scroll position
			body.style.overflow = '';
			// -------- [END] Ham Icon

			if (ww >= 1024) {
				header.classList.remove(hamburgerMenuClassIsActive);
				mainMenu.classList.remove(hamburgerMenuClassIsActive);

				window.docEnableScroll && docEnableScroll();
			}

			off(document, 'keydown', docKeyboard);
			result.isOpen = false;
		}

		var docKeyboard = function (e) {
			if (e.keyCode === 27) {
				// Esc
				mainMenuClose();

				// 選取內容第一個Focus物件外層 focus + blur
				mainContFirstCTA.focus();
				mainContFirstCTA.blur();
			}
		}

		// 漢堡選單：按下
		on(hamburgerMenu, 'click', function () {
			if (!result.isOpen) {
				mainMenuOpen();
			} else {
				mainMenuClose();
			}
		});

		var fdOffCanvas = $('[data-off-canvas]'); // Foundation OffCanvas 物件
		fdOffCanvas
			.on('opened.zf.offCanvas', function () {
				result.isOpen = true;
			})
			.on('closed.zf.offCanvas', function () {
				if (!breakpointChanging) {
					// 手機板轉換ＰＣ版破壞時，會先關閉選單導致事件觸發，避免原本應該要開起來的選單被關起
					result.isOpen = false;
				}
			});

		var breakpointChanging = false;
		var hamMenuBreakpoint = window.matchMedia('(min-width: 1024px)');
		var hamMenuBreakpointChecker = function () {
			breakpointChanging = true;
			setTimeout(() => {
				breakpointChanging = false;
			}, 500);

			if (hamMenuBreakpoint.matches) {
				// Large
				if (result.isOpen) {
					// 打開
					mainMenuOpen();
				} else {
					// 關起
					mainMenuClose();
				}
			} else {
				// Small
				if (result.isOpen) {
					// 打開
					fdOffCanvas.foundation('open');
				} else {
					// 關起
					fdOffCanvas.foundation('close');
				}
			}
		};
		hamMenuBreakpoint.addListener(hamMenuBreakpointChecker);

		result.open = mainMenuOpen;
		result.close = mainMenuClose;

		return result;
	}

	let hamMenu = null
	on(window, 'load', function() {
		hamMenu = hamburgerMenuClickBuild();
	});
	/* ----------------------------------- [END] Hamburger Click */

	/* ----------------------------------- [START] 選單下滑更改樣式 */
	// const doc = document.documentElement;

	const header = document.querySelector('#header');
	// const headerClassScroll = 'is-collapse';
	const headerClassScrollDown = 'is-scroll-down';
	const headerClassScrollUp = 'is-scroll-up';
	const headerClassFixed = 'is-menu-fixed';

	let windowScrollTopCache = getScrollTop();
	let windowScrollStatus = null;

	// const hdCollapseEvent = new CustomEvent('collapse');
	// const hdUnCollapseEvent = new CustomEvent('uncollapse');

	function observe(o, callback) {
		return new Proxy(o, {
			set(target, property, value) {
				switch (typeof target[property]) {
					case 'boolean': case 'string':
						if (target[property] === value) return;
						break;
				}
				callback(property, value);
				target[property] = value;
			}
		})
	}

	const headerStatus = {
		collapse: false
	}

	const headerStatusObserve = observe(headerStatus, (property, value) => {
		if (property === 'collapse') {
			if (value) {
				// collapse
				header.classList.add(headerClassScroll);
				html.classList.add(headerClassScroll);

				// header.dispatchEvent(hdCollapseEvent);
			} else {
				// not Collapse
				header.classList.remove(headerClassScroll);
				html.classList.remove(headerClassScroll);

				// header.dispatchEvent(hdUnCollapseEvent);
			}
		}
	});

	/**
	 * 更改向上滑動與向下滑動狀態
	 * @param {string} dir 滑動方向，輸入['down'|'up']
	 */
	function scrollStatusChange(dir) {
		if (windowScrollStatus === dir) {
			return false;
		} else {
			if (dir === 'down') {
				scrollStatusDown(header);
				scrollStatusDown(html);
			} else {
				scrollStatusUp(header);
				scrollStatusUp(html);
			}
			windowScrollStatus = dir;
		}
	}

	function scrollStatusDown(el) {
		el.classList.add(headerClassScrollDown);
		el.classList.remove(headerClassScrollUp);
	}

	function scrollStatusUp(el) {
		el.classList.remove(headerClassScrollDown);
		el.classList.add(headerClassScrollUp);
	}

	/* 滑動主要Function */
	function headerScrollHandler() {
		ws = getScrollTop();

		if (ws > 0) {
			header.classList.add(headerClassFixed);
			html.classList.add(headerClassFixed);
		} else {
			header.classList.remove(headerClassFixed);
			html.classList.remove(headerClassFixed);
		}

		// 確認上滑與下滑狀態
		if (ws > windowScrollTopCache) {
			scrollStatusChange('down');
		} else if (ws !== windowScrollTopCache) {
			scrollStatusChange('up');
		}
		windowScrollTopCache = ws;

		// 下滑超過一定高度出現樣式：更改選單樣式、GoTop隱藏出現
		if (ws >= 200 && !header.classList.contains(hamburgerMenuClassIsActive)) {
			headerStatusObserve.collapse = true;
			
			// 移動到: headerStatusObserve
			// header.classList.add(headerClassScroll);
			// html.classList.add(headerClassScroll);
		} else {
			headerStatusObserve.collapse = false;

			// 移動到: headerStatusObserve
			// header.classList.remove(headerClassScroll);
			// html.classList.remove(headerClassScroll);
		}

		fixedNavScroll();
		ftFixedShow();
	}
	on(window, 'load', headerScrollHandler);
	on(window, 'scroll', headerScrollHandler);
	/* ----------------------------------- [END] 選單下滑更改樣式 */

	/* ----------------------------------- [START] Page Nav */
	const fixedNavEl = $('.js-fixed-nav');
	const fixedNavOffset = [];
	const fixedNavClassFixed = 'is-fixed';
	const hdClassHasPageNav = 'has-page-nav';
	let fixedNavHeaderHeight = 0;
	let fixedNavHeight = 0;

	function fixedNavInit() {
		fixedNavEl.each((index, item) => {
			fixedNavOffset.push($(item).offset().top);
		});

		fixedNavHeaderHeight = header.clientHeight;
		fixedNavHeight = fixedNavEl.innerHeight();

		// 為了可以調整Toast的位置而設置
		html.classList.add(hdClassHasPageNav);
	}

	function fixedNavScroll() {
		fixedNavEl.each((index, item) => {
			if (ws > 0 && ws + fixedNavHeaderHeight > fixedNavOffset[index]) {
				item.classList.add(fixedNavClassFixed);
				item.style.height = fixedNavHeight + 'px';

				// 下滑要 Fade out 項目
				if (item.classList.contains('page-nav--fade-out')) {
					header.classList.remove(hdClassHasPageNav);
				}
			} else {
				item.classList.remove(fixedNavClassFixed);
				item.style.height = '';

				// 下滑要 Fade out 項目
				if (item.classList.contains('page-nav--fade-out')) {
					header.classList.add(hdClassHasPageNav);
				}
			}
		});
	}

	on(window, 'load', function () {
		// 檢測頁面是否有項目 => 執行
		if (fixedNavEl.length) {
			header.classList.add(hdClassHasPageNav);

			// Start
			fixedNavInit();
			fixedNavScroll();
		}
	});
	/* ----------------------------------- [END] Page Nav */

	/* ----------------------------------- [START] Page Scroll Active */
	function navScrollActiveBuild(els, pos) {
		pos = pos || 'left';
		els.each((index, item) => {
			var list = $(item);
			var targetActive = list.find('.is-active');
			if (targetActive.length) {
				var offsetLeft = pos === 'left'
					? targetActive.get(0).offsetLeft - 15
					: targetActive.get(0).offsetLeft - list.innerWidth() / 2 + targetActive.innerWidth() / 2;
				list.animate({ scrollLeft: offsetLeft }, 300);
			}
		});
	}

	on(window, 'load', function() {
		const navScrollActive = $('[data-scroll-to-active]');
		if (navScrollActive.length) {
			navScrollActiveBuild(navScrollActive, 'center');
		}

		const pageNav = $('.page-nav__scroll-area[data-scroll-detect]');
		if (pageNav.length) {
			navScrollActiveBuild(pageNav, 'left');
		}
	})
	/* ----------------------------------- [END] Page Scroll Active */

	/* ----------------------------------- [START] Scroll Detected */
	let scrollDetectInterface = null;
	class ScrollDetect {
		constructor (customOpt) {
			const defaultOpt = {
				el: null, // 指定物件 [String|Object]
				// arrow: false, // 是否使用箭頭 (未寫)
				// mouseWheel: false, // 是否有滑鼠滾輪 (未寫)
				// mouseDrag: false, // 是否有滑鼠拖曳 (未寫)
				scrollEl: null, // 監測 Scroll 的物件，預設 el
				statusEl: null, // 更改狀態物件（has-scroll, is-scroll-start, is scroll-end），預設 el
				hasScrollClass: 'has-scroll', // 有 scroll bar class
				startClass: 'is-scroll-start', // scroll bar 在頭
				endClass: 'is-scroll-end', // scroll bar 在尾
			}

			const opt = {
				...defaultOpt,
				...customOpt
			}

			opt.scrollEl = opt.scrollEl || opt.el;
			opt.statusEl = opt.statusEl || opt.el;

			this.params = opt;
			this.hasScroll = false;

			scrollDetectInterface = this;

			this._init(opt);
		}

		_init (opt) {
			const params = this.params;

			this.selector = typeof opt.el === 'object'
				? opt.el
				: document.querySelector(opt.el);

			this.checkScroll(this.selector);
			this.scrollDetected();

			params.scrollEl.scrollDetect = {};
			params.scrollEl.scrollDetect.params = opt;

			window.addEventListener("resize", (e) => {
				this.checkScroll();
			});

			params.scrollEl.addEventListener("scroll", (e) => {
				this.scrollDetected();
			});
		}

		checkScroll () {
			const params = this.params;

			this.hasScroll = params.scrollEl.scrollWidth > params.scrollEl.clientWidth;

			if (this.hasScroll) {
				params.statusEl.classList.add(params.hasScrollClass);
			} else {
				params.statusEl.classList.remove(params.hasScrollClass);
			}
		}

		scrollDetected () {
			const params = this.params
				? this.params
				: this.tagName
					? this.scrollDetect.params
					: scrollDetectInterface.params;
			
			// 沒有滾軸直接讓左右物件消失
			if (!this.hasScroll) {
				params.statusEl.classList.remove('is-scroll-start');
				params.statusEl.classList.remove('is-scroll-end');
				return false;
			}

			// Disabled - 向左箭頭
			if (params.scrollEl.scrollLeft === 0) {
				params.statusEl.classList.add('is-scroll-start');
			} else {
				params.statusEl.classList.remove('is-scroll-start');
			}

			// Disabled - 向右箭頭
			if (params.scrollEl.scrollLeft + params.scrollEl.clientWidth === params.scrollEl.scrollWidth) {
				params.statusEl.classList.add('is-scroll-end');
			} else {
				params.statusEl.classList.remove('is-scroll-end');
			}
		}
	}
	
	const scrollDetectEl = document.querySelectorAll('.page-sub-nav__scroll-area[data-scroll-detect]');

	on(window, 'load', function() {
		if (scrollDetectEl.length) {
			[].forEach.call(scrollDetectEl, function(el) {
				const scrollDetect = new ScrollDetect({
					el: el,
					statusEl: el.parentElement.parentElement
				})
			});
		}
	})
	/* ----------------------------------- [END] Scroll Detected */

	/* ----------------------------------- [START] GoTop */
	// 1. offset()
	var ft = $('#footer');
	// var ftOffsetTop = ft.offset().top;
	var ftFixedEl = $('#ft-fix-box');
	// var ftFixedElHeight = null;
	var ftFixedClassSticky = 'is-fixed';
	// var ftFixedAlign = 'top'; //
	// var ftFixedOffset = {
	// 	0: -20,
	// 	640: 50,
	// };

	/* ----- */
	/* Go Top Click */
	var goTop = $('#go-top');
	goTop.on('click touchstart', function (e) {
		e.preventDefault();
		$('html, body')
			.stop()
			.animate(
				{
					scrollTop: 0,
				},
				400,
				'swing',
				function () {
					document.querySelector('a').focus();
					document.querySelector('a').blur();
				}
			);
	});

	/* ----- */
	// 取得修正數值
	var goTopScrollOffset = 0;

	// 取得FooterOffsetTop
	var getFooterOffsetTop = function () {
		ftOffsetTop = ft.offset().top;
	};
	on(window, 'resize', throttle(getFooterOffsetTop, 50, 100));

	/* Go Top 出現 */
	function ftFixedShow() {
		if (ws > 100) {
			ftFixedEl.removeClass('is-top');
			// ftFixedEl.css({ opacity: '1', 'pointer-events': '' });
			// 因為Footer位置可能會一直變動 => 寫在Scroll Event內
			// 2021/09/30 客戶不要，因為產品內頁手機板會擋到內容
			// if (ws + wh > ft.offset().top + goTopScrollOffset) {
			// 	ftFixedEl.removeClass(ftFixedClassSticky);
			// } else {
			// 	ftFixedEl.addClass(ftFixedClassSticky);
			// }
		} else {
			ftFixedEl.addClass(ftFixedClassSticky);
			// ftFixedEl.css({ opacity: '0', 'pointer-events': 'none' });
			ftFixedEl.addClass('is-top');
		}
	}
	on(window, 'load', ftFixedShow);
	/* ----------------------------------- [END] GoTop */

	/* ----------------------------------- [START] Menu(class) */
	class Menu {
		constructor(options) {
			const defaults = {
				btnEl: null, // 開啟的按鈕
				menuEl: null, // 選單
				classOpen: 'is-open',
				classParent: 'is-menu-wrapper',
				docClick: true, // 是否啟用 Document Click
				esc: true, // 是否啟用 Esc 關閉
				on: null,
			};

			for (const key in defaults) {
				options[key] = options[key] || defaults[key];
			}

			// Callback(on Event)
			const events = {
				open: null,
				close: null,
				closeEsc: null, // 按下 ESC 關閉
			};
			if (!options.on) {
				options.on = events;
			}

			this.options = options;

			// Change Selector to Element
			const getElList = ['btnEl', 'menuEl'];
			for (const key of getElList) {
				if (typeof this.options[key] === 'string') {
					this.options[key] = document.querySelector(this.options[key]);
				}
			}

			this.addClass(this.options.menuEl, this.options.classParent);
			this.isOpen = false;

			this.create();

			return this;
		}

		create() {
			const _this = this;
			const options = _this.options;

			// Document Click
			if (options.docClick) {
				_this.docClick = function (e) {
					const _thisFn = this;
					if (!_thisFn.parents(e.target, _thisFn.options.classParent)) {
						_thisFn.close();
					}
				};
				_this.docClickHandler = _this.docClick.bind(_this);
			}

			// Document keyboard
			if (options.esc) {
				_this.docKeyboard = function (e) {
					if (e.keyCode === 27) {
						// Esc
						this.close();

						if (this.options.on.closeEsc && typeof this.options.on.closeEsc === 'function') {
							this.options.on.closeEsc();
						}
					}
				};
				_this.docKeyboardHandler = _this.docKeyboard.bind(_this);
			}

			_this.on(options.btnEl, 'click', () => {
				_this.open();
			});
		}

		on(target, event, func, option) {
			target = target || window;
			if (window.addEventListener) {
				var opt = option || false;
				target.addEventListener(event, func, opt);
			} else {
				target.attachEvent('on' + event, func);
			}
		}

		off(target, event, func) {
			target = target || window;
			if (window.addEventListener) target.removeEventListener(event, func, false);
			else target.detachEvent('on' + event, func);
		}

		addClass(el, className) {
			el.classList.add(className);
		}

		removeClass(el, className) {
			el.classList.remove(className);
		}

		toggleClass(el, className) {
			el.classList.toggle(className);
		}

		parents(el, targetClass) {
			while (!el.classList.contains(targetClass) && el !== document.body) {
				el = el.parentElement;
			}
			if (el === document.body) el = null;
			return el;
		}

		open() {
			const _this = this;
			const options = _this.options;
			options.menuEl.classList.add(options.classOpen);
			if (options.on.open && typeof options.on.open === 'function') {
				options.on.open();
			}

			if (!_this.isOpen) {
				_this.isOpen = true;

				setTimeout(() => {
					options.docClick && _this.on(document, 'click', _this.docClickHandler);
					options.esc && _this.on(document, 'keydown', _this.docKeyboardHandler);
				}, 10);
			}
		}

		close() {
			const _this = this;
			const options = _this.options;
			options.docClick && _this.off(document, 'click', _this.docClickHandler);
			options.esc && _this.off(document, 'keydown', _this.docKeyboardHandler);
			options.menuEl.classList.remove(options.classOpen);
			if (options.on.close && typeof options.on.close === 'function') {
				options.on.close();
			}
			_this.isOpen = false;
		}
	}
	/* ----------------------------------- [END] Menu(class) */

	/* ----------------------------------- [START] Header Menu */
	/* 選單項目開啟關閉(Header Menu, Footer Sitemap) */
	// new ToggleMenu(el: selector, {option}, callback)
	class ToggleMenu {
		constructor(el, options, callback) {
			const _self = this;

			const defaults = {
				enableSize: null, // 裝置寬多少以下(含)執行
				sync: false, // 是否同步影響其它項目
				disabledActive: false, // 是否無視Active，即點擊Active不會收合
				disabledActiveEnable: null, // 「是否無視Active」在裝置寬（含）以上執行
			}

			const opt = {
				...defaults,
				...options
			}

			const { enableSize, sync, disabledActive, disabledActiveEnable } = opt;

			var toggleMenuEl = document.querySelectorAll(el);

			if (toggleMenuEl === undefined || toggleMenuEl === null || toggleMenuEl.length <= 0) return false;

			_self.toggleMenuEl = toggleMenuEl;
			_self.callback = callback;
			_self.params = opt;
			
			_self.active = 0;
			const activeClass = 'is-active';

			// 紀錄目前 Active Index
			// 要記錄在外層，避免無法同步
			if (sync) {
				[].forEach.call(toggleMenuEl, function (el, index) {
					if (el.parentElement.classList.contains(activeClass)) {
						_self.active = index;
					}
				});
			}

			[].forEach.call(toggleMenuEl, function (el, index) {
				el.menu = {
					el: el,
					list: toggleMenuEl,
					parent: el.parentElement,
					subLink: el.nextElementSibling ? el.nextElementSibling.querySelectorAll('a') : null,
					classOpen: activeClass,
					classTl3d: 'translate3d',
					timer: null,
				};

				if (!el.menu.subLink) return false;

				// Click事件
				on(el, 'click', function (event) {
					let enable = true;

					// 是否執行「是否無視Active」，是Active時不收合
					if (disabledActive) {
						// 條件A: 沒有設定 disabledActiveEnable
						// 條件B: 有設定 disabledActiveEnable，並在範圍內
						if (disabledActiveEnable === null || (disabledActiveEnable !== null && ww >= disabledActiveEnable)) {
							if (el.menu.parent.classList.contains(activeClass)) {
								enable = false;
							}
						}
					}

					if (
						enable &&
						(enableSize ? ww <= enableSize : true) &&
						!el.classList.contains('is-native') &&
						el.menu.parent.classList.contains('has-sub')
					) {
						// 停止原生事件(避免有連結的會跳轉)
						event.preventDefault();

						// Blur
						this.blur();

						_self.to(index);
					}
				});
			});

			_self.to = _self.to;
			_self.next = _self.next;
			_self.prev = _self.prev;
		}

		to (index) {
			const _this = this;
			const selfMenu = _this.toggleMenuEl[index].menu;

			// 開啟瀏覽器加速效能(transform3d(0,0,0))
			selfMenu.subLink.forEach((item) => {
				item.classList.add(selfMenu.classTl3d);
			});

			// 移除原本的Active
			if (_this.params.sync) {
				if (_this.active !== index) {
					_this.active !== -1 && selfMenu.list[_this.active].parentElement.classList.remove(selfMenu.classOpen);
					_this.active = index;
				} else {
					_this.active = -1;
				}
			}

			// Toggle open Class
			selfMenu.parent.classList.toggle(selfMenu.classOpen);

			// 清除transform3d
			if (selfMenu.timer !== null) clearTimeout(selfMenu.timer);
			selfMenu.timer = setTimeout(function () {
				selfMenu.subLink.forEach((item) => {
					item.classList.remove(selfMenu.classTl3d);
				});
			}, 1000);

			if (typeof _this.callback === 'function') {
				_this.callback.call(_this.toggleMenuEl[index]);
			}
		}

		next () {
			const _this = this;
			const index = (_this.active + 1 + _this.toggleMenuEl.length) % _this.toggleMenuEl.length;
			_this.to(index);
			return _this.toggleMenuEl[index].menu;
		}
		
		prev () {
			const _this = this;
			const index = (_this.active - 1 + _this.toggleMenuEl.length) % _this.toggleMenuEl.length;
			_this.to(index);
			return _this.toggleMenuEl[index].menu;
		}
	}

	on(window, 'load', function () {
		/* Footer Sitemap */
		// var hdMenu = new ToggleMenu('.hd-menu__link', null, true);
		var hdMenu = new ToggleMenu('.hd-menu__link', {
			sync: true,
			disabledActive: true,
			disabledActiveEnable: 1024
		});

		// 優化鍵盤操作部分（Tab / Tab + Shift）
		if (!isMobile) {
			var mainMenuGroups = mainMenu.querySelector('.hd-main-menu').querySelectorAll('.hd-menu__item');
			mainMenuGroups.forEach((group, index) => {
				var mainMenuLink = group.querySelectorAll('.hd-menu__sec__link');
				mainMenuLink.forEach((item, i) => {
					on(item, 'focus', function() {
						if (hdSearchMenu && hdSearchMenu.isOpen) {
							hdSearchMenu.close();
						}
						
						if (hdMemberMenu && hdMemberMenu.isOpen) {
							hdMemberMenu.close();
						}

						if (!hamMenu.isOpen) {
							hamMenu.open();
						}
					});

					// First
					if (i === 0) {
						if (index !== 0) {
							// Other group's first
							on(item, 'keydown', function(event) {
								// Tab + Shift
								if (event.keyCode === 9 && event.shiftKey) {
									event.preventDefault();
									const selfMenu = hdMenu.prev();
									selfMenu.subLink[selfMenu.subLink.length - 1].focus();
								}
							});
						} else {
							// First group's first
							on(item, 'keydown', function(event) {
								// Tab + Shift
								if (event.keyCode === 9 && event.shiftKey) {
									hamMenu.close();

									// 如果會員選單存在 => Focus 會員選單最後一項
									if (hdMemberMenu) {
										setTimeout( () => {
											memberMenuSecLinkLast.focus();
										}, 100)
									}
								}
							});
						}
					}

					// Last
					if (i === mainMenuLink.length - 1) {
						if (index !== mainMenuGroups.length - 1) {
							// Other group's last
							on(item, 'keydown', function(event) {
								// Tab (Not Shift + Tab)
								if (event.keyCode === 9 && !event.shiftKey) {
									event.preventDefault();
									const selfMenu = hdMenu.next();
									selfMenu.subLink[0].focus();
								}
							});
						} else {
							// Last group's last
							on(item, 'keydown', function(event) {
								// Tab (Not Shift + Tab)
								if (event.keyCode === 9 && !event.shiftKey) {
									hamMenu.close();
								}
							});
						}
					}
				});

			});
		}
	});
	/* ----------------------------------- [END] Header Menu */

	/* ----------------------------------- [START] Header Search */
	let hdSearchMenu = null; // 紀錄 Header Search 的 new Menu 物件
	let hdMemberMenu = null; // 紀錄 Header Member Menu (PC) 的 new Menu 物件
	let hdMemberBtn = document.querySelector('.hd__r__item.member').querySelector('a');
	function hdSearchBuild() {
		const searchOpenBtn = header.querySelector('.hd-btn--search');
		const searchPanel = header.querySelector('#hd-search-panel');
		const searchInput = searchPanel.querySelector('.hd-search__input');
		const searchCancel = searchPanel.querySelector('.hd-search__cancel');
		const searchSubmit = searchPanel.querySelector('.hd-search__button-submit');
		const isFocus = 'is-search-focus';
		// const isSearching = 'is-searching';
		const isOpen = 'is-search-open';

		hdSearchMenu = new Menu({
			btnEl: searchOpenBtn,
			menuEl: searchPanel,
			classOpen: isOpen,
			on: {
				open () {
					searchInput.focus();
				},
				close () {
					searchElClassRemove(isFocus);
				},
				// 優化鍵盤操作部分
				closeEsc () {
					if (!isMobile) {
						searchOpenBtn.focus();
						searchOpenBtn.blur();
					}
				}
			},
		});

		const searchElClassRemove = function (className) {
			header.classList.remove(className);
			searchPanel.classList.remove(className);
		};

		on(searchCancel, 'click', function () {
			// PC
			hdSearchMenu.close();
		});

		// 優化鍵盤操作部分
		on(searchSubmit, 'focus', function () {
			if (!isMobile && ww >= 1024 && !hdSearchMenu.isOpen) {
				hdSearchMenu.open();
				if (hdMemberMenu && hdMemberMenu.isOpen) {
					hdMemberMenu.close();
				}
			}
		});

		// 優化鍵盤操作部分
		$(document).on('focus', '.hd-search__list a', function() {
			if (!isMobile && ww >= 1024 && !hdSearchMenu.isOpen) {
				hdSearchMenu.open();
				this.focus();
				if (hdMemberMenu && hdMemberMenu.isOpen) {
					hdMemberMenu.close();
				}

				if (hamMenu.isOpen) {
					hamMenu.close();
				}
			}
		});

		// 優化鍵盤操作部分 (Tab + Shift)
		on(searchInput, 'keydown', function(event) {
			if (event.keyCode === 9 && event.shiftKey) {
				event.preventDefault();
				hdSearchMenu.close();
				hdMemberBtn.focus();
			}
		});
	}

	function searchBoxBuild() {
		const searchCont = document.querySelectorAll('.hd-search__cont');
		searchCont.forEach((item) => {
			const isHeader = item.parentElement.classList.contains('hd-search');
			const input = item.querySelector('.hd-search__input');
			const clean = item.querySelector('.hd-search__clean');
			const searchPanel = isHeader ? item.parentElement : item;
			const classIsFocus = 'is-search-focus';
			const classIsSearching = 'is-searching';
			const classIsHide = 'hide';

			let recommendShow = false;

			const searchElClassAdd = function (className) {
				searchPanel.classList.add(className);
				if (isHeader) {
					header.classList.add(className);
				}
			};
	
			const searchElClassRemove = function (className) {
				searchPanel.classList.remove(className);
				if (isHeader) {
					header.classList.remove(className);
				}
			};
	
			const searchCheckValue = function () {
				if (input.value.trim().length > 0) {
					searchElClassAdd(classIsSearching);
					clean.classList.remove(classIsHide);
				} else {
					searchElClassRemove(classIsSearching);
					clean.classList.add(classIsHide);
				}
			};

			// ==========================

			const recommendOpen = function() {
				recommendShow = true;
				searchElClassAdd(classIsFocus);
			}

			const recommendClose = function() {
				recommendShow = false;
				searchElClassRemove(classIsFocus);
			}

			// ==========================

			// Start
			searchCheckValue();

			on(input, 'focus', function () {
				recommendOpen();
				html.classList.add('search-input-is-focus');
			});

			on(input, 'focusout', function () {
				html.classList.remove('search-input-is-focus');
			});

			// Focusout
			if (input.getAttribute('data-close-on-focusout') === 'true') {
				on(input, 'focusout', function () {
					// 為了要可以按下方連結，所以延後關閉
					setTimeout(() => {
						recommendClose();
					}, 50);
				});
			}

			// Esc
			if (input.getAttribute('data-close-on-esc') === 'true') {
				on(document, 'keydown', function(e) {
					if (e.keyCode === 27) {
						recommendClose();
					}
				});
			}

			on(input, 'input', function() {
				searchCheckValue();
				if (!recommendShow) {
					recommendShow = true;
					searchElClassAdd(classIsFocus);
				}
			});

			on(input, 'change', function () {
				setTimeout(() => {
					searchCheckValue();
				}, 20);
			});

			on(clean, 'click', function () {
				setTimeout(() => {
					input.value = '';
					input.focus();
				}, 10);
				clean.classList.add(classIsHide);
				searchElClassRemove(classIsSearching);
			});
		});
	}

	on(window, 'load', function () {
		hdSearchBuild();
		searchBoxBuild();
	});
	/* ----------------------------------- [END] Header Search */

	/* ----------------------------------- [START] Header Member */
	let memberMenuSecLinkLast; // 為了讓別處可以call
	function hdMemberBuild() {
		let timer = null;
		const memberOpenBtn = header.querySelector('.hd-btn--member-sign-in');

		if (memberOpenBtn === null) {
			return false;
		}

		const memberPanel = header.querySelector('.hd-menu__list.hd-mem');
		memberPanel.setAttribute('tabindex', -1);
		const memberMenuLink = memberPanel.querySelectorAll('a');
		memberMenuSecLinkLast = memberMenuLink[memberMenuLink.length - 1]; // 會員選單的最後一項

		hdMemberMenu = new Menu({
			btnEl: memberOpenBtn,
			menuEl: memberPanel,
			on: {
				// 優化鍵盤操作部分
				open () {
					if (!isMobile) {
						memberPanel.focus();
						memberPanel.blur();
					}
				}
			}
		});

		memberMenuLink.forEach((item) => {
			on(item, 'click', function () {
				hdMemberMenu.close();
			});

			// 優化鍵盤操作部分
			if (!isMobile) {
				on(item, 'focus', function () {
					// PC
					if (timer) {
						clearTimeout(timer)
					}
	
					if (hdMemberMenu && !hdMemberMenu.isOpen) {
						hdMemberMenu.open();
					}
	
					if (hdSearchMenu && hdSearchMenu.isOpen) {
						hdSearchMenu.close();
					}
				});
			}
		});
	}

	on(window, 'load', hdMemberBuild);
	/* ----------------------------------- [END] Header Member */

	/* ----------------------------------- [START] Footer */
	// Copyright Year
	// 隨著電腦日期更換年份
	const footerYYYY = document.querySelector('.ft-copyright__year');
	if (footerYYYY !== null) {
		footerYYYY.innerHTML = new Date().getFullYear();
	}

	// Area & Language
	var langPanels = $('.ft-select-box.lang');
	var areaPanel = $('.ft-select-box.area');
	var areaPanelList = areaPanel.find('ul');
	var areaPanelLink = areaPanel.find('a');
	var langPanelLink = langPanels.find('a');
	areaPanelLink.on('click', function () {
		var $this = $(this);

		// 隱藏舊的語言選擇項目
		langPanels.addClass('hide');
		areaPanelLink.removeClass('is-active');

		// 更換所有選項的語言選擇文字為「選擇語言」
		var chooseText = langPanels.eq(0).find('.ft-select-box__button').attr('title');
		langPanels.find('.ft-select-box__button').text(chooseText);

		// 顯示選到的語言項目
		$this.addClass('is-active'); // 對項目 <a> 增加 "is-active" class
		$this.parents('.ft-select-box').find('.ft-select-box__button').text($this.text()); // 更改為目前選擇的文字
		$('#' + $this.data('show')).removeClass('hide'); // 顯示目前選擇的區域「語言」
		areaPanelList.attr('aria-activedescendant', $this.parent().attr('id')); // 更改 aria 設定

		// 關閉Dropdown
		$('#ft-area__list').foundation('close');
	});
	langPanelLink.on('click', function () {
		var $this = $(this);

		// 顯示選到的語言項目
		$this.parents('.ft-select-box').find('.ft-select-box__button').text($this.text()); // 更改為目前選擇的文字

		// 關閉Dropdown
		$(this).parents('ul').foundation('close');
	});
	/* ----------------------------------- [END] Footer */

	/* ----------------------------------- [START] ScrollTriggerAni */
	// 有滑動到該區增加動態者增加class "js-ani" ， 滑動到該區增加class "is-animated"
	// 用套件也好，換其它習慣方式也罷，請達成↑目的
	let aniSec = document.querySelectorAll('.js-ani');
	const scrollTriggerAniThrottle = throttle(scrollTriggerAni, 200, 500); // 節流作用

	function scrollTriggerAni() {
		for (var i = 0; i < aniSec.length; i++) {
			if (isInViewport(aniSec[i])) {
				aniSec[i].classList.add('is-animated');
			}
		}

		cleanTrigger();

		/* If load all of the item, stop Listener */
		if (aniSec.length === 0) {
			off(window, 'scroll', scrollTriggerAniThrottle);
		}
	}

	function cleanTrigger() {
		aniSec = Array.prototype.filter.call(aniSec, function (ani) {
			return !ani.classList.contains('is-animated');
		});
	}

	on(window, 'load', scrollTriggerAni);
	on(window, 'scroll', scrollTriggerAniThrottle);
	/* ----------------------------------- [END] ScrollTriggerAni */

	/* ========================================================== */

	/* ----------------------------------- [START] 功能 */
	// 加入我的最愛
	// const favBtn = document.querySelectorAll('.js-fav');
	const favClass = 'is-active';
	const favClick = function(el) {
		const _this = el;

		_this.classList.toggle(favClass);
			
		if (_this.classList.contains(favClass)) {
			// Active
			_this.setAttribute('title', _this.dataset['tipRemoved']);
			_this.setAttribute('aria-label', _this.dataset['tipRemoved']);
			toast('success', _this.dataset['toastAdded']);
		} else {
			// Remove
			_this.setAttribute('title', _this.dataset['tipAdded']);
			_this.setAttribute('aria-label', _this.dataset['tipAdded']);
			toast('error', _this.dataset['toastRemoved']);
		}
	}
	window.favClick = favClick; // 為了讓HTML可直接Call（避免有伺服器錯誤的問題）
	/* ================== */
	// 貨到通知
	// const noticeBtn = document.querySelectorAll('.js-notice');
	const noticeClass = 'is-active';
	const noticeClick = function(el) {
		const _this = el;

		_this.classList.toggle(noticeClass);
			
		if (_this.classList.contains(noticeClass)) {
			// Active
			_this.innerText = _this.dataset['textRemoved'];
			toast('success', _this.dataset['toastAdded']);
		} else {
			// Remove
			_this.innerText = _this.dataset['textAdded'];
			toast('success', _this.dataset['toastRemoved']);
		}
	}
	window.noticeClick = noticeClick;
	/* ----------------------------------- [END] 功能 */

	/* ---------------------------------------- [START] Tel Change el */
	/* 如果不是 Mobile 時，用<span>取代<a>取消連結
	 * 沒有 RWD breakpoint 變換
	 */
	var telElArray = [];
	function telChangeBuild() {
		var telChangeEl = document.querySelectorAll('.js-tel-change');
		if (telChangeEl.length) {
			[].forEach.call(telChangeEl, function (el) {
				var normalEl = createElementFromHTML(el.outerHTML.replace(/^\<a/, '<span').replace(/<\/a>/, '</span>')); // 取代為 span
				normalEl.removeAttribute('href');

				var obj = {
					link: el,
					normal: normalEl,
				};

				telElArray.push(obj);
			});

			if (!isMobile) {
				telElArray.forEach(function (item) {
					var oldEl = item.link;
					var newEl = item.normal;
					oldEl.parentNode.replaceChild(newEl, oldEl);
				});
			}
		}
	}
	on(window, 'load', telChangeBuild);
	/* ---------------------------------------- [END] Tel Change el */

	/* ---------------------------------------- [START] Placeholder Change on Small (Mobile) */
	const placeholderChangeEls = document.querySelectorAll('[data-placeholder-small]');
	function placeholderChangeBuild() {
		const breakpoint = window.matchMedia('(min-width: 1024px)'); // This point up use long text
		const breakpointChecker = function() {
			if (breakpoint.matches) {
				// Large
				placeholderChangeLarge();
			} else {
				// Small
				placeholderChangeSmall();
			}
		}

		// Init: 取得設定
		const placeholderChangeInit = function() {
			placeholderChangeEls.forEach((item) => {
				const setting = {
					normal: item.getAttribute('placeholder'),
					small: item.getAttribute('data-placeholder-small')
				}

				item.placeholderSetting = setting; // 至於物件上

				// 刪除屬性
				item.removeAttribute('data-placeholder-small');
			});
		}

		// 更換長提示
		const placeholderChangeLarge = function() {
			placeholderChangeEls.forEach((item) => {
				if (item.placeholderSetting) {
					item.placeholder = item.placeholderSetting.normal;
				}
			});
		}

		// 更換短提示
		const placeholderChangeSmall = function() {
			placeholderChangeEls.forEach((item) => {
				if (item.placeholderSetting) {
					item.placeholder = item.placeholderSetting.small;
				}
			});
		}

		placeholderChangeInit();
		breakpointChecker();
		breakpoint.addListener(breakpointChecker);
	}
	on(window, 'load', function () {
		if (placeholderChangeEls.length) {
			placeholderChangeBuild();
		}
	});
	/* ---------------------------------------- [END] Placeholder Change on Small (Mobile) */

	/* ----------------------------------- [START] Password - 顯示隱藏 */
	const passwordViewEls = document.querySelectorAll('.js-pw-view');
	function passwordViewBuild() {
		const passwordViewClassActive = 'is-active';

		const setCont = function(item, text) {
			item.setAttribute('aria-label', text);
			item.setAttribute('title', text);
		}

		passwordViewEls.forEach((item, index) => {
			const setting = {
				show: item.dataset['show'] || item.getAttribute('data-show'),
				hide: item.dataset['hide'] || item.getAttribute('data-hide'),
				group: item.dataset['group']
					? document.querySelectorAll(`.js-pw-view[data-group="${item.dataset['group']}"]`)
					: []
			}

			item.pw = setting; // 至於物件上

			// 移除屬性
			item.removeAttribute('data-show');
			item.removeAttribute('data-hide');

			const statusChange = function(isTypePassword, {
				currentItem,
				currentInput,
				textHide,
				textShow
			}) {
				// if (input.getAttribute('type') === 'password')
				if (isTypePassword) {
					// 目前隱藏 → 顯示
					setCont(currentItem, textHide); // 更換文字
					currentItem.classList.add(passwordViewClassActive);
					currentInput.setAttribute('type', 'text'); // 更換 input 類型

				} else {
					// 目前顯示 → 隱藏
					setCont(currentItem, textShow); // 更換文字
					currentItem.classList.remove(passwordViewClassActive);
					currentInput.setAttribute('type', 'password'); // 更換 input 類型
				}
			}

			// 點擊事件
			item.addEventListener('click', function() {
				const _this = this;
				const input = _this.parentElement.querySelector('input');

				const isTypePassword = input.getAttribute('type') === 'password';
				const textShow = _this.pw.show;
				const textHide = _this.pw.hide;

				if (_this.pw.group.length) {
					// 所有同一個Group一起開啟
					for(let i = 0; i < _this.pw.group.length; i++) {
						const currentItem = _this.pw.group[i];
						const currentInput = currentItem.parentElement.querySelector('input');

						statusChange(isTypePassword, {
							currentItem,
							currentInput,
							textHide,
							textShow
						})
					}
				} else {
					// 如果沒有設定 data-group 情況 => 只改自己
					statusChange(isTypePassword, {
						currentItem: _this,
						currentInput: input,
						textHide,
						textShow
					});
				}

			});
		});
	}
	on(window, 'load', function() {
		if (passwordViewEls.length) {
			passwordViewBuild();
		}
	});
	/* ----------------------------------- [END] Password - 顯示隱藏 */

	/* ----------------------------------- [START] Product List - 顏色提示位置計算 */
	let pdListColorList = null;

	function pdListColorInit() {
		let resizeThrottle = null;

		// 計算每個卡片內容寬給予顏色列表
		const getParentWidth = function() {
			this.style.setProperty('--width', `${this.parentElement.clientWidth}px`);
		}

		const removeParentWidth = function() {
			this.style.setProperty('--width', '');
		}

		const getEachParentWidth = function() {
			pdListColorList.forEach((list) => {
				list.getParentWidth = getParentWidth.bind(list);
				list.removeParentWidth = removeParentWidth.bind(list);
				list.getParentWidth();
			});
		}

		const removeEachParentWidth = function() {
			if(pdListColorList[0].removeParentWidth === undefined) return;
			pdListColorList.forEach((list) => {
				list.removeParentWidth();
			});
		}

		// 計算提示文字位置
		const onMouseenter = function() {
			if (this.colorWidthCheck) return;

			const item = this;
			const $item = $(item);
			const $tip = $(item.querySelector('.color-list__tip'));
			const $wrapper = $tip.parents('.pd-list__item');
			const offsetR = $wrapper.innerWidth() - ($item.offset().left - $wrapper.offset().left + $item.innerWidth() / 2); // 圓心中心往右距離

			// Tip 是否與右邊距離 15px，沒有則要調整
			if (offsetR - $tip.innerWidth() / 2 < 15) {
				$tip.get(0).style.setProperty('--move', Math.round((offsetR - $tip.innerWidth() / 2 - 15) * 100) / 100 + 'px');
			}

			item.colorWidthCheck = true; // 確認已檢查過
		}

		// 監測每個子項目事件
		const calcColorTipPos = function() {
			pdListColorList.forEach((list) => {
				const items = list.children;
				for (let i = 0; i < items.length; i++) {
					on(items[i], 'mouseenter', onMouseenter);
				}
			});
		}

		const unCalcColorTipPos = function() {
			pdListColorList.forEach((list) => {
				const items = list.children;
				for (let i = 0; i < items.length; i++) {
					off(items[i], 'mouseenter', onMouseenter)
				}
			});
		}

		// 移除提示位置計算
		const removeColorTipPos = function() {
			pdListColorList.forEach((list) => {
				const items = list.children;
				for (let i = 0; i < items.length; i++) {
					const item = items[i];
					const $tip = item.querySelector('.color-list__tip');
					$tip.style.setProperty('--move', '');
					item.colorWidthCheck = false;
				}
			});
		}

		const resize = function() {
			getEachParentWidth();
			removeColorTipPos();
		}

		const rebuild = function() {
			destroy();
			mounted();
		}

		const destroy = function() {
			removeEachParentWidth();
			removeColorTipPos();
			unCalcColorTipPos();
			off(window, 'resize', resizeThrottle);
		}

		const mounted = function() {
			pdListColorList = document.querySelectorAll('.pd-list__color-list');
			
			

			// [Start] 計算每個卡片內容寬給予顏色列表
			// 避免情況，所以使用延遲
			// 1. 物件有 Swiper
			// 2. 選單介面展開收起（3欄、4欄交替）

			// 1. 找到有Swiper的物件
			setTimeout(() => {
				getEachParentWidth();
			}, 500);

			// 計算顏色位置
			calcColorTipPos();

			resizeThrottle = throttle(resize, 50, 100);

			on(window, 'resize', resizeThrottle);
		}

		// Start
		mounted();

		return {
			resize,
			rebuild,
			destroy,
		}
	}

	on(window, 'load', function() {
		if (document.querySelector('.pd-list__color-list') === null) return;
		const pdListColor = pdListColorInit();
		window.pdListColorRebuild = pdListColor.rebuild;
	});
	/* ----------------------------------- [END] Product List - 顏色提示位置計算 */
})(window, document);

/* ----------------------------------- [START] Video Popup (配合LazyLoad) */
/* Video Popup (配合LazyLoad) */
/* ----------------------------------- */
// v1 Index 使用
(function (window, document) {
	var videoBox = $('.video-box');
	var youTubeSetting =
		'?rel=0&showinfo=0&autoplay=1&loop=1&modestbranding=1&iv_load_policy=0&widget_referrer=0&&enablejsapi=1';
	var youTubeAllow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
	var useCookie = false; // 是否使用Cookie
	var youTubeDomain = useCookie ? 'https://www.youtube.com' : 'https://www.youtube-nocookie.com'; // 不使用Cookie的話，右上分享會變成「複製連結」

	var videoIframe = null;

	var buildVideoBox = function () {
		if (videoBox.length === 0) return false;

		var videoPopup = $('#popup-video');
		var videoPopupPlayer = videoPopup.find('.video-player');

		videoBox.each(function () {
			var currentItem = $(this);
			var youTubeUrl = currentItem.data('video');
			var youTubeId = getYoutubeId(youTubeUrl);
			var videoElTitle = currentItem.attr('title') || 'Video';

			// 移除為了 NO JS 設置的 tabindex="-1"
			currentItem.find('.video-box__play-btn').removeAttr('tabindex');

			// Get Title
			// getYouTubeTitle(youTubeId, function(title) {
			// 	currentItem.parent().find('.video-box__title').text(title)
			// });

			// 寫入Properties
			this.videoId = youTubeId;

			var videoImg = currentItem.find('.video-box__preview');
			var videoPlayPopup = currentItem.find('[data-video-popup]');

			var videoIframeSrc = `${youTubeDomain}/embed/${youTubeId}${youTubeSetting}`;
			var youTubeIframeString = `<iframe width="1080" height="608" class="video-player" title="${videoElTitle}" src="${videoIframeSrc}" frameborder="0" allow="${youTubeAllow}" allowfullscreen></iframe>`;

			// Video Cover
			var videoCoverData = currentItem.data('cover');
			var videoCover =
				videoCoverData !== undefined ? videoCoverData : 'https://img.youtube.com/vi/' + youTubeId + '/0.jpg';
			if (videoImg.hasClass('lazy')) {
				// 有LazyLoad
				videoImg.attr('data-src', videoCover);
				if (videoImg.parents('.swiper-slide') !== undefined) {
					// 有Swiper的情況
					videoImg.addClass('swiper-lazy');
				} else {
					// 一般情況
					if (document.body.setLazy !== undefined) document.body.setLazy();
				}
			} else {
				// 沒有Lazy
				videoImg.css({
					'background-image': 'url(' + videoCover + ')',
				});
			}

			// Click and load iframe
			videoPlayPopup.on('click', function () {
				videoPopupPlayer.append(youTubeIframeString);

				videoIframe = videoPopupPlayer.get(0).querySelector('.video-player');
				$(videoIframe).on('load', function () {
					setTimeout(function () {
						youTubePlay(videoIframe);
					}, 1000);
				});
			});
		});

		// videoPopup.on('closed.zf.reveal', function () {
		// 	$(this).find('iframe').remove();
		// });
	}

	// Start
	on(window, 'load', buildVideoBox);

	// -----------------------------------
	// 讀取網址
	// get-youtube-info.js: https://gist.github.com/ramonvictor/5629626
	function getYoutubeId(url){
		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
		var match = url.match(regExp);
		if (match && match[7].length === 11) {
			return match[7];
		}
		return false;
	}

	// 讀取標題
	// Reference: https://stackoverflow.com/a/32190892
	// function getYouTubeTitle(id, callback) {
	// 	var url = 'https://www.youtube.com/watch?v=' + id;
	// 	$.getJSON('https://noembed.com/embed',
	// 		{format: 'json', url: url}, function (data) {
	// 			if(typeof callback === 'function') callback(data.title);
	// 			return data.title
	// 	});
	// }

	// ----------------------------------- Start : YouTube Control
	// YouTube 控制
	// https://stackoverflow.com/a/46195259
	// 影片播放
	var domain = youTubeDomain || 'https://www.youtube.com';
	function youTubePlay(el) {
		if (el !== null && el.contentWindow !== null)
			el.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', domain);
	}
	// 影片暫停
	function youTubeStop(el) {
		if (el !== null && el.contentWindow !== null)
			el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', domain);
	}
	// 影片重播
	function youTubeReplay(el) {
		if (el !== null && el.contentWindow !== null) {
			el.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', domain);
			el.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', domain);
		}
	}
	// ----------------------------------- End : YouTube Control
})(window, document);

// Video Popup v2
/*	PUG 範例：
	.js-video-play(
		data-video="影片路徑",
		data-open="popup-video",
		data-player="播放類型[ty(YouTube不用寫)|file]"
		data-type="【只適用於file播放】播放影片類型（配合<video> <source> 的 type 屬性）"
		)
	*/
// v2 除了 Index 使用
(function(window, document) {
	// 取得項目
	const videoPlayEl = document.querySelectorAll('.js-video-play');

	let videoIframe = null; // 裝載 YouTube Video Iframe（用來操作自動播放）

	// YouTube 基本設定
	const ytSetting = '?rel=0&showinfo=0&autoplay=1&loop=1&modestbranding=1&iv_load_policy=0&widget_referrer=0&&enablejsapi=1';
	const ytAllow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
	const ytUseCookie = false; // 是否使用Cookie
	const ytDomain = ytUseCookie ? 'https://www.youtube.com' : 'https://www.youtube-nocookie.com'; // 不使用Cookie的話，右上分享會變成「複製連結」

	const videoPopup = document.querySelector('#popup-video');
	const videoPopupPlayer = videoPopup && videoPopup.querySelector('.video-player') || null; // 放置影片位置

	function videoPlayBuild() {
		// 頁面上沒有項目 => 不執行
		if (videoPlayEl.length === 0) {
			return false
		}

		// Build Item
		videoPlayEl.forEach((item) => {
			// 類型 [yt|file]
			const type = item.dataset['videoPlayer'] || 'yt';
			const videoSrc = item.dataset['video'];
			const videoElTitle = item.dataset['title'] || item.getAttribute('title') || 'Video';
			
			if (type === 'yt') {
				// YouTube 情況
				const ytId = getYoutubeId(videoSrc);

				// Get Title
				// getYouTubeTitle(youTubeId, function(title) {
				// 	item.parentElement.querySelector('.video-box__title').innerText = title;
				// });

				// 寫入Properties
				item.videoId = ytId;

				// [START] 設置封面圖片
				const videoImg = item.querySelector('.video-box__preview');
				if (videoImg) {
					// Video Cover
					const videoCoverData = item.dataset['cover']; // 讀取 data-cover 值

					// 確認是否有 Cover 值，沒有 => 讀取YouTube 圖片
					const videoCover =
						videoCoverData !== undefined ? videoCoverData : 'https://img.youtube.com/vi/' + youTubeId + '/0.jpg';

					if (videoImg.classList.contains('lazyload')) {
						// 有LazyLoad
						videoImg.setAttribute('data-src', videoCover);

						const swiperParent = closest(item, function(el) {
							return (el.classList && el.classList.contains('.swiper-slide'));
						});

						if (swiperParent) {
							// 有Swiper的情況
							videoImg.classList.add('swiper-lazy');
						} else {
							// 一般情況 （目前專案不適用 => 隱藏）
							// if (document.body.setLazy !== undefined) {
							// 	document.body.setLazy();
							// };
						}

					} else {
						// 沒有Lazy
						videoImg.style.backgroundImage = `url('${videoCover}')`;
					}
				}
				// [END] 設置封面圖片

				// [START] 建立播放器 <iframe>
				const playSrc = `${ytDomain}/embed/${ytId}${ytSetting}`;
				const playElStr = `<iframe width="1080" height="608" class="video-player" title="${videoElTitle}" src="${playSrc}" frameborder="0" allow="${ytAllow}" allowfullscreen></iframe>`;
				const playEl = createElementFromHTML(playElStr);

				item.playEl = playEl; // 寫入物件
				// [END] 建立播放器 <iframe>
			}

			else if (type === 'file') {
				// 一般檔案

				// [START] 建立播放器 <iframe>
				const playSrc = videoSrc;
				const playType = item.dataset['videoType'] || 'video/mp4';
				const playElStr = `<video class="video-player" width="1080" height="608" title="${videoElTitle}" controls="" autoplay="">
									<source src="${playSrc}" type="${playType}">
									Your browser does not support the video tag.
								</video>`;
				const playEl = createElementFromHTML(playElStr);

				item.playEl = playEl; // 寫入物件
				// [END] 建立播放器 <iframe>
			}

			item.addEventListener('click', function() {
				// 生成播放器
				videoPopupPlayer.appendChild(this.playEl);

				// 自動播放
				videoIframe = videoPopupPlayer.querySelector('.video-player');
				if (type === 'yt') {
					// YouTube
					videoIframe.addEventListener('load', function() {
						youTubePlay(videoIframe);
					});
				} else if (type === 'file') {
					// 一般檔案
					videoIframe.play();
				}
			});
		});
	}

	// Start
	window.addEventListener('load', function() {
		videoPlayBuild();

		// Foundation Reveal Close Event
		// Foundation 事件監聽一定要是jQ物件寫法
		// 因為有新舊版本，把 Foundation 事件寫這
		$(videoPopup).on('closed.zf.reveal', function () {
			// 清除播放器物件
			videoPopupPlayer.innerHTML = '';
			videoIframe = null;
		});
	});

	// ----------------------------------- [START] Tool
	// find nearest parent element
	function closest(el, fn) {
		return el && ( fn(el) ? el : closest(el.parentNode, fn) );
	};

	// 從 String 取得 HTML Element
	// https://stackoverflow.com/a/494348/11240898
	function createElementFromHTML(htmlString) {
		var div = document.createElement('div');
		div.innerHTML = htmlString.trim();

		// Change this to div.childNodes to support multiple top-level nodes
		return div.firstChild;
	}
	// ----------------------------------- [END] Tool



	// ----------------------------------- [START] YouTube Tool
	// 讀取網址
	// get-youtube-info.js: https://gist.github.com/ramonvictor/5629626
	function getYoutubeId(url){
		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
		var match = url.match(regExp);
		if (match && match[7].length === 11) {
			return match[7];
		}
		return false;
	}

	// 讀取標題
	// Reference: https://stackoverflow.com/a/32190892
	// function getYouTubeTitle(id, callback) {
	// 	var url = 'https://www.youtube.com/watch?v=' + id;
	// 	$.getJSON('https://noembed.com/embed',
	// 		{format: 'json', url: url}, function (data) {
	// 			if(typeof callback === 'function') callback(data.title);
	// 			return data.title
	// 	});
	// }

	// ========== YouTube Control ==========
	// YouTube 控制
	// https://stackoverflow.com/a/46195259
	// 影片播放
	var domain = ytDomain || 'https://www.youtube.com';

	function youTubePlay(el) {
		if (el !== null && el.contentWindow !== null)
			el.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', domain);
	}

	// 影片暫停
	// function youTubeStop(el) {
	// 	if (el !== null && el.contentWindow !== null)
	// 		el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', domain);
	// }

	// 影片重播
	// function youTubeReplay(el) {
	// 	if (el !== null && el.contentWindow !== null) {
	// 		el.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', domain);
	// 		el.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', domain);
	// 	}
	// }
	// ----------------------------------- [END] YouTube Tool

})(window, document);
/* ----------------------------------- [END] Video Popup (配合LazyLoad) */

/* ----------------------------------- [START] WebP Support */
/* WebP Support */
/* ----------------------------------- */
(function (window, document){
	// Reference: https://stackoverflow.com/a/5573422
	// Reference: https://davidwalsh.name/detect-webp (ES7)
	// let hasWebP = false;

	const buildWebpDetect = function (){
		var imgWebP = new Image();
		imgWebP.onload = function () {
			// hasWebP = !!(imgWebP.height > 0 && imgWebP.width > 0);
			// does support
			document.documentElement.classList.add('webp');
		};
		imgWebP.onerror = function () {
			// hasWebP = false;
			// does not support
			document.documentElement.classList.add('no-webp');
		};
		imgWebP.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
	};

	window.addEventListener('load', buildWebpDetect);
})(window, document);
/* ----------------------------------- [END] WebP Support */


/* ----------------------------------- [START] Plugin - toastr(Toast) */
/* Plugin - toastr(Toast) */
/* ----------------------------------- */
(function(){

	function toastrSetting() {
		// 避免尚未讀取
		if (toastr === undefined) {
			return setTimeout(() => {
				toastrSetting()
			}, 1000);
		}

		toastr.options = {
			// "closeButton": false,
			// "debug": false,
			"newestOnTop": false,
			// "progressBar": true, // 倒數時間軸，2021/09/29 移除倒數時間軸
			"positionClass": "toast-top-center",
			// "preventDuplicates": true,
			// "onclick": null,
			"showDuration": "300",
			"hideDuration": "300",
			"timeOut": 2000,
			// "extendedTimeOut": "1000",
			// "showEasing": "swing",
			// "hideEasing": "linear",
			// "showMethod": "fadeIn",
			// "hideMethod": "fadeOut"
			// ============ Event ============
			// onShow 事件是額外修改套件增加的，在生成事件的一開始（物件尚未生成）
			// ※因為onShown（官方原本設定的事件）觸發時間點太慢，導致調整 ProgressBar 時間過慢，倒數 Bar 出現的太晚（幾乎只剩一半）
			onShow: function() { 
				const _this = this;
				// 因為物件尚未生成，所以延遲讀取，避免讀不到
				setTimeout(() => {
					// 手動更改寬（避免倒數時間軸超出範圍）
					_this.style.width = _this.querySelector('.toast-message').clientWidth + 2 + 'px'; // display: inline-block 會有誤差值2
				}, 10);
			},
		}

		const toastType = ['success', 'error', 'warning', 'info']; // Toast type

		// 包裝成 Toast
		// ※因為用 ::before, ::after 很難對齊置中，改用<i> 的形式進行
		// 要統一管理，所以用 function  包裝
		/**
		 * Toast Function
		 * @param {string} type - 類型 [success | error | warning | info]
		 * @param {string} msg - 訊息
		 * 如果只單獨輸入訊息 toast(msg)，則顯示 info 類型
		 */
		function toast(type, msg, delay) {
			const opt = delay
				? { timeOut: delay }
				: {};

			// 確認 Type 是否在名單內
			if (!toastType.includes(type) && arguments.length === 1) {
				// 如果不設定 Type 情況，直接輸入
				// 把 type 當作 msg
				toastr.info(`<i class="be-icon be-icon-info"></i><span class="text">${type}</span>`, null, opt);
			} else {
				// 在 Type 的名單內
				switch (type) {
	
				case 'success':
					toastr.success(`<i class="be-icon be-icon-check-circle"></i><span class="text">${msg}</span>`, null, opt);
					break;
	
				case 'error':
					toastr.error(`<i class="be-icon be-icon-close-circle"></i><span class="text">${msg}</span>`, null, opt);
					break;
	
				case 'warning':
					toastr.warning(`<i class="be-icon be-icon-exclamation-circle"></i><span class="text">${msg}</span>`, null, opt);
					break;
					
				default:
					toastr.info(`<i class="be-icon be-icon-info-circle"></i><span class="text">${msg}</span>`, null, opt);
					break;
				}
			}
		}

		window.toast = toast; // 讓整體專案可以 Call
	}

	window.addEventListener('load', function() {
		toastrSetting();
	});
})();
/* ----------------------------------- [END] Plugin - toastr(Toast) */

/* ---------------------------------------- [START] 輸入 Filter */
(function (window, document) {
	/* 只能輸入數字 */
	// setInputFilter(document.querySelectorAll('.js-input-num'), function (value) {
	// 	return /^\d*$/.test(value);
	// });

	/* 只能輸入數字 + 英文 */
	setInputFilter(document.querySelectorAll('.js-input-num-az'), function (value) {
		return /^[a-zA-Z0-9]*$/.test(value);
	});

	/* 只能輸入數字 + 英文 + Dash */
	setInputFilter(document.querySelectorAll('.js-input-num-az-dash'), function (value) {
		return /^[a-zA-Z0-9-]*$/.test(value);
	});

	/* 只能輸入電話相關 */
	setInputFilter(document.querySelectorAll('.js-input-tel'), function (value) {
		return /^[\d-+\s()#]*$/.test(value);
	});
})(window, document);

/* Ref (Richart): https://richart.tw/TSDIB_RichartWeb/static/revamp/js/InputFilter.js
 * Restricts input for the given textbox to the given inputFilter.
 */
function setInputFilter(x, inputFilter) {
	if (x.length > 0) {
		['focus', 'input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(
			function (event) {
				for (var i = 0; i < x.length; i++) {
					x[i].addEventListener(event, function () {
						if (inputFilter(this.value)) {
							this.oldValue = this.value;
							this.oldSelectionStart = this.selectionStart;
							this.oldSelectionEnd = this.selectionEnd;
						} else if (this.hasOwnProperty('oldValue')) {
							this.value = this.oldValue;
							this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
						} else {
							this.value = '';
						}
					});
				}
			}
		);
	}
}
/* ---------------------------------------- [END] 輸入 Filter */

/* ---------------------------------------- [START] Custom Select */
(function(window, document) {
	
	function buildCustomSelect() {
		// [Plugin] jQuery customSelect: https://kvlsrg.github.io/jquery-custom-select/
		$(".js-custom-select").customSelect({
			includeValue: true
		});

		// $(".js-custom-select").attr('tabindex', -1);
	}

	window.addEventListener('load', buildCustomSelect);

})(window, document);
/* ---------------------------------------- [END] Custom Select */

/* ---------------------------------------- [START] Cookie 通知 */
(function (window, document) {
	var cookieNotice = document.querySelector('#cookie-notice');
	var cookieMaxAge; // 紀錄時效
	var setName = 'cookieNoticeHide';

	function checkCookie() {
		/* 頁面上沒有 Cookie 物件 => 不執行 */
		if (!cookieNotice) {
			return false;
		}

		var cookieNoticeBtn = cookieNotice.querySelector('button');
		var cookieNoticeBtnClose = cookieNotice.querySelector('[data-close]');

		/* 確認是否已經有確認Cookie 或 曾經按過叉（2022/07/27新增） */
		if (getCookie(setName) === 'true' || localStorage.getItem('cookieClosed') === 'true') {
			/* 已有確認 */
			cookieNotice.remove();
		} else {
			/* 未確認 */
			cookieNotice.classList.remove('is-hide');

			/* 取得過期時間設定 */
			cookieMaxAge = cookieNotice.getAttribute('data-max-age') || 180;
			if (typeof cookieMaxAge === 'string') {
				cookieMaxAge = parseInt(cookieMaxAge);
			}
		}

		var closeHandler = function() {
			cookieNotice.classList.add('to-hide');
			setTimeout(function () {
				cookieNotice.remove();
			}, 1000);
		}

		/* 按下「確認」功能 */
		cookieNoticeBtn.addEventListener('click', function () {
			setCookie(setName, true, cookieMaxAge); // 時效設定單位：天
			closeHandler();
		});

		// /* 按下「有連結的按鈕」功能 */
		// if (cookieNoticeBtnAnchor) {
		// 	cookieNoticeBtnAnchor.addEventListener('click', function() {
		// 		closeHandler();
		// 	});
		// }

		/* 按下「關閉」功能 */
		if (cookieNoticeBtnClose) {
			cookieNoticeBtnClose.addEventListener('click', function() {
				closeHandler();

				// 2022/07/27 客戶希望增加設定，按下叉後，永遠不顯示
				localStorage.setItem('cookieClosed', true);
			});
		}
	}

	window.addEventListener('load', checkCookie);

	/* ------------------------------------------------------- Tool */
	/* https://stackoverflow.com/a/24103596/11240898 */
	function setCookie(name, value, days) {
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	}

	function getCookie(name) {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i].trim();
			if (c.indexOf(nameEQ) === 0) {
				return c.replace(nameEQ, '');
			}
		}
		return null;
	}
})(window, document);
/* ---------------------------------------- [END] Cookie 通知 */
