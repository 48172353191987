/* ---------------------------------------- [START] Window EventListener */
window.on = function (target, event, func, option) {
	target = target || window;
	if (window.addEventListener) {
		var opt = option || false;
		target.addEventListener(event, func, opt);
	} else {
		target.attachEvent('on' + event, func);
	}
};
window.off = function (target, event, func) {
	target = target || window;
	if (window.addEventListener) target.removeEventListener(event, func, false);
	else target.detachEvent('on' + event, func);
};
/* ---------------------------------------- [END] Window EventListener */

/* ---------------------------------------- [START] 簡單的節流函數 */
// https://www.cnblogs.com/coco1s/p/5499469.html
window.throttle = function (func, wait, mustRun) {
	var timeout;
	var startTime = new Date();

	return function () {
		var context = this;
		var args = arguments;
		var curTime = new Date();

		if (timeout !== undefined) {
			if (window.requestTimeout !== undefined) clearRequestTimeout(timeout);
			else clearTimeout(timeout);
		}
		// 如果達到了規定的觸發時間間隔，觸發 handler
		if (curTime - startTime >= mustRun) {
			func.apply(context, args);
			startTime = curTime;
			// 沒達到觸發間隔，重新設定定時器
		} else {
			if (window.requestTimeout !== undefined) timeout = requestTimeout(func, wait);
			else timeout = setTimeout(func, wait);
		}
	};
};
/* ---------------------------------------- [END] 簡單的節流函數 */

/* ---------------------------------------- [START] isInViewport */
window.isInViewport = function (el) {
	var rect = el.getBoundingClientRect();

	var isVisible = el.offsetHeight !== 0;

	return (
		isVisible &&
		rect.bottom >= 0 &&
		rect.right >= 0 &&
		rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.left <= (window.innerWidth || document.documentElement.clientWidth)
	);
};
/* ---------------------------------------- [END] isInViewport */

/* ---------------------------------------- [START] Disable/Enable Scroll */
window.docDisableScroll = function(showScroll) {
	var html = document.documentElement;
	var top = (window.pageYOffset || html.scrollTop)  - (html.clientTop || 0);
	window.scrollTopCache = top;
	window.disabledScroll = true;
	if (showScroll) {
		html.style.cssText += "position: fixed; width: 100%; overflow-x: hidden; overflow-y: scroll; top:-" + top + "px;";
	} else {
		html.style.cssText += "position: fixed; width: 100%; overflow-y: hidden; top:-" + top + "px;";
	}
}
window.docEnableScroll = function() {
	var html = document.documentElement;
	html.style.cssText = '';
	// 避免還沒設定過 scrollTopCache ，導致值錯誤
	if (window.disabledScroll === undefined) {
		var top = (window.pageYOffset || html.scrollTop)  - (html.clientTop || 0);
		window.scrollTopCache = top;
	}
	window.scrollTo(0, window.scrollTopCache);
	window.scrollTopCache = 0;
}
/* ---------------------------------------- [END] Disable/Enable Scroll */

/* ---------------------------------------- [START] 從 String 取得 HTML Element */
// https://stackoverflow.com/a/494348/11240898
function createElementFromHTML(htmlString) {
	var div = document.createElement('div');
	div.innerHTML = htmlString.trim();

	// Change this to div.childNodes to support multiple top-level nodes
	return div.firstChild;
}
/* ---------------------------------------- [END] 從 String 取得 HTML Element */

/* ---------------------------------------- [START] 防止IE沒有 JS element.remove() */
/* Create Element.remove() function if not exist */
if (!('remove' in Element.prototype)) {
	Element.prototype.remove = function () {
		if (this.parentNode) {
			this.parentNode.removeChild(this);
		}
	};
}
/* ---------------------------------------- [END] 防止IE沒有 JS element.remove() */

/* ---------------------------------------- [START] 取得正確的資源位置 */
// https://stackoverflow.com/a/26023176/11240898
/**
 * Current Script Path | 取得正確的資源位置
 *
 * Get the dir path to the currently executing script file
 * which is always the last one in the scripts array with
 * an [src] attr
 */
function currentScriptPath(regexPath) {
	// 取得記錄在 session storage 的資料
	const key = 'currentScriptPath';
	const data = sessionStorage.getItem(key);

	// 如果有則直接輸出
	if (data) {
		return data
	}

	var scripts = document.querySelectorAll('script[src]');
	var currentScriptEl = null;

	if (regexPath) {
		const regex = new RegExp(regexPath);
		// 指定偵測路徑
		currentScriptEl = [].find.call(scripts, (item) => {
			return item.src.match(regex)
		})

	} else {
		// 未指定則抓最後一個資料
		currentScriptEl = scripts[scripts.length - 1];
	}

	var currentScript = currentScriptEl.src;
	var currentScriptChunks = currentScript.split('/');
	var currentScriptFile = currentScriptChunks[currentScriptChunks.length - 1];

	var result = currentScript.replace(currentScriptFile, '').split('js/')[0];

	// 將結果推送到 Session Storage
	sessionStorage.setItem(key, result);
	
	return result;
}
/* ---------------------------------------- [END] 取得正確的資源位置 */
